<template>
  <div class="card-detail-view"></div>
</template>

<script>
export default {
  name: 'card-detail',
  async created() {
    const target = this.getCard()
    if (!target) {
      this.$message.error('没有银行卡', 2)
      await this.$router.push('/member_center/bank-card')
    }
  },
  methods: {
    getCard() {
      return this.$store.state.bankCards.find(
        ({ card_id }) => card_id === this.$route.params.id
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.card-detail-view {
}
</style>
